.custom-col-resize:hover{
    cursor: col-resize;
}

.custom-col-resize::before {
    content: "";
    position: relative;
    display: inline-block;
    height: 60px;
    width: 1px;
    background-color: #879596;
    top: 46%;
    left: 5px;
}

.custom-col-resize::after {
    content: "";
    position: relative;
    display: inline-block;
    height: 60px;
    width: 1px;
    background-color: #879596;
    top: 46%;
    left: 8px;
}